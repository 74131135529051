<template>
  <div class="magazine-container">
    <b-row>
      <b-col cols="12" class="mb-32">
        <page-title />
      </b-col>
      <b-card class="col-12">

        <b-row class="justify-content-between">
          <b-col class="pr-md-32 pr-md-120">
            <div class="search-section">
              <div class="search-box"> <b-form-input v-model="keywords" type="text" class="col-12"></b-form-input></div>
              <div class="search-button"><b-button variant="outline-black-100"
                  v-on:click="search(keywords)"><i class="ri-search-line remix-icon"></i>  <span>ค้นหา</span>
                </b-button></div>
              <div class="search-button"><b-button variant="outline-black-100" v-on:click="addNewarticleType()"><i
                    class="hp-text-color-dark-0 ri-2x ri-add-fill"></i></b-button></div>
            </div>

          </b-col>
          <b-col class="pr-md-32 ">
            <div class="breadcrumb-custom">
              <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <div class="col-12 mt-16">
            <b-table :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" stacked="md"
              show-empty class="mt-24" sticky-header>
              <template #cell(name)="row">
                {{ row.value.firstname_eng }} {{ row.value.lastname_eng }}
              </template>

              <template #cell(actions)="row">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-xl-6 ">
                    
                  </div>
                  <div class="col-sm-12 col-md-12 col-xl-6 ">
                    <b-button class="mb-8 mr-8" variant="outline-warning"
                      @click="edit(row.item, row.index, $event.target)">
                      <i class="hp-text-color-dark-0 iconly-Light-Edit"></i>
                    </b-button>
                    <b-button class="mb-8 mr-8" v-b-modal.modal-danger variant="outline-danger"
                      @click="del(row.item, row.index, $event.target)">
                      <i class="hp-text-color-dark-0 iconly-Light-Delete"></i>
                    </b-button>
                  </div>
                </div>
              </template>
            </b-table>

            <b-row>
              <div class="col-5">
                <div class="hp-p1-body ">
                  หน้าปัจจุบัน: {{ currentPage }} เรียงลำดับจาก:
                  <b>{{ sortDesc ? 'มากไปหาน้อย' : 'น้อยไปหามาก' }}</b>
                </div>
              </div>
              <div class="col-3">
                <b-col class="hp-flex-none w-auto">
                  <b-form-group label-for="per-page-select" label-size="sm" class="mb-0">
                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </div>
              <div class="col-4">
                <b-col class="hp-flex-none w-auto">
                  <b-pagination align="end" id="my-table" v-model="currentPage" :total-rows="allItems"
                    :per-page="perPage"></b-pagination>
                </b-col>
              </div>
            </b-row>
          </div>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton, BTable, BPagination, BFormInput, BFormSelect, BFormGroup, BModal, BBreadcrumb, BSpinner } from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import router from "../../../../router";
import articleTypeService from "../../../../services/article-type.service";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    PageTitle,
    BPagination,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BModal,
    BBreadcrumb,
    BSpinner
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      sortBy: 'type_name',
      sortDesc: false,
      fields: [
      { key: 'type_name', sortable: true, label: 'ชื่อประเภท' },
        { key: 'category_name', sortable: true, label: 'ชื่อหมวดหมู่' },
        { key: "actions", sortable: false, label: '' },
      ],
      items: [],
      pageOptions: [10, 15, 30, 50, { value: 100, text: "Show a lot" }],
      codeActive: false,
      codeActiveClass: false,
      allItems: 0,
      keywords: null,
      breadcrumbItems: [
        {
          text: "บทความ",
          href: "#",
        },
        {
          text: "ประเภท",
          active: true,
        },
      ],
      isLoading: false
    };
  },

  watch: {
    currentPage: async function () {
      await this.getArticleTypes(this.keywords, this.currentPage, this.perPage)
    },
    sortBy: async function () {
      let orderby = this.sortDesc ? "desc" : "asc";
      await this.getArticleTypes(this.keywords, this.currentPage, this.perPage, this.sortBy, orderby)
    },
    sortDesc: async function () {
      let orderby = this.sortDesc ? "desc" : "asc";
      await this.getArticleTypes(this.keywords, this.currentPage, this.perPage, this.sortBy, orderby)
    },
  },
  methods: {
    getArticleTypes: async function (keywords, currentPage) {
      let orderby = this.sortDesc ? "desc" : "asc";
      let list = await articleTypeService.getArticleTypes(keywords, currentPage, this.perPage, this.sortBy, orderby);
      let dataList = list.data.article_types;

      let totalItems = list.data.allItems;
      this.allItems = totalItems;
      this.items = dataList;
      console.log(this.items)
    },
    search: async function (keywords) {
      let orderby = this.sortDesc ? "desc" : "asc";
      let list = await articleTypeService.getArticleTypes(keywords, this.currentPage, this.perPage, this.sortBy, orderby);
      let dataList = list.data.article_types;
      this.allItems = list.data.allItems;
      this.items = dataList;
    },
    edit(articleType, index, event) {
      router.push({ path: "/pages/articles/types/edit-type/:id", name: 'article-edit-type', params: { id: articleType.id } })

    },
    del(articleType, index, event) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบประเภทบทความนี้หรือไม่", {
          title: "กรุณายืนยันการทำรายการ",
          okVariant: "danger",
          okTitle: "ยืนยัน",
          cancelTitle: "ไม่ยืนยัน",
          cancelVariant: "text",
        })
        .then(async (isConfirm) => {
          if (isConfirm) {
            this.isLoading = true;
            await articleTypeService.deleteArticleType(articleType.id);
            await this.getArticleTypes(this.keywords, this.currentPage, this.perPage);
            this.isLoading = false;
          }

        })
        .catch((err) => {
          this.$bvModal
            .msgBoxConfirm("ไม่สามารถลบประเภทบทความนี้ได้", {
              title: "ผลยืนยันการทำรายการ",
              okVariant: "danger",
              okTitle: "ยืนยัน",
              cancelTitle: "ไม่ยืนยัน",
              cancelVariant: "text",
            })
            .then(async (value) => {
              console.log();
            })
        });
    },
    addNewarticleType() {
      router.push({ path: "/pages/articles/types/add-type", name: 'article-add-type' })
    }
  },
  mounted: async function () {
    await this.getArticleTypes(this.keywords, this.currentPage, this.perPage);
  },
};
</script>
